import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../../view/LogIn/index';
import Dashboard from '../../view/Dashboard';

import Misc from '../../view/Misc';
import DashboadUser from '../../view/DashboadUser';

import AccountView from '../../view/Account/View';
import AccountList from '../../view/Account/List/';

import TransactionView from '../../view/Transaction/View';
import TransactionList from '../../view/Transaction/List/';

import NotFound404 from '../../view/404/index';

import ProtectedRoutes from './ProtectedRoutes';

const Router = () => {
    return (
        <MainWrapper>
            <main>
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/dashboard" />
                    </Route>

                    <Route path="/404" component={NotFound404} />

                    <Route path="/logout" />

                    <Route exact path="/login" component={LogIn} />

                    <Route path="/dashboard">
                        <div>
                            <Layout />
                            <div className="container__wrap">
                                <Switch>
                                    <ProtectedRoutes exact path="/dashboard" component={Dashboard} />
                                    <Route>
                                        <Redirect to="/dashboard" />
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                    </Route>

                    <Route path="/dashboard-user">
                        <div>
                            <Layout />
                            <div className="container__wrap">
                                <Switch>
                                    <ProtectedRoutes exact path="/dashboard-user" component={DashboadUser} />
                                    <Route>
                                        <Redirect to="/dashboard" />
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                    </Route>

                    <Route path="/misc-sc">
                        <div>
                            <Layout />
                            <div className="container__wrap">
                                <Switch>
                                    <ProtectedRoutes exact path="/misc-sc" component={Misc} />
                                    <Route>
                                        <Redirect to="/dashboard" />
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                    </Route>

                    <Route path="/account">
                        <div>
                            <Layout />
                            <div className="container__wrap">
                                <Switch>
                                    <ProtectedRoutes exact path="/account/create" component={AccountView} />
                                    <ProtectedRoutes exact path="/account/view" component={AccountList} />
                                    <ProtectedRoutes exact path="/account/update/:id" component={AccountView} />
                                    <Route>
                                        <Redirect to="/dashboard" />
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                    </Route>
                    <Route path="/transaction">
                        <div>
                            <Layout />
                            <div className="container__wrap">
                                <Switch>
                                    <ProtectedRoutes exact path="/transaction/create" component={TransactionView} />
                                    <ProtectedRoutes exact path="/transaction/view" component={TransactionList} />
                                    <ProtectedRoutes exact path="/transaction/update/:id" component={TransactionView} />
                                    <Route>
                                        <Redirect to="/dashboard" />
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                    </Route>
                    <Route>
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </main>
        </MainWrapper>
    );
};

export default Router;
