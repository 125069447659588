import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
//SERVICE CALL
import { logOut } from '../../Services/dataService';

const SidebarContent = ({ onClick }) => {
    useEffect(() => {}, []);

    return (
        <div className="sidebar__content">
            <ul className="sidebar__block">
                <SidebarLink title="Dashboard" icon="home" route="/dashboard" onClick={onClick} />

                <SidebarLink icon="envelope" title="Misc SC" route="/misc-sc" onClick={onClick} />
                <SidebarLink icon="envelope" title="Dashboard User" route="/dashboard-user" onClick={onClick} />

                <SidebarLink icon="envelope" title="Accounts" route="/account/view" onClick={onClick} />

                <SidebarLink icon="envelope" title="Transaction" route="/transaction/view" onClick={onClick} />

                <SidebarLink
                    icon="exit"
                    title="Log Out"
                    route="/logout"
                    onClick={() => {
                        // logOut(getFlag() === '1' ? '/systemadmin' : '/');
                        logOut('/');
                    }}
                />
            </ul>
        </div>
    );
};

SidebarContent.propTypes = {
    // changeToDark: PropTypes.func.isRequired,
    // changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
    sidebarCollapse: false,
};

export default SidebarContent;
