import React from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import Table from './components/Table';
import { Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FormValidation = () => {
    return (
        <>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={1000} />

            <Container>
                <Row>
                    <Col md={6} xs={6}>
                        <h3 className="page-title">Account List</h3>
                    </Col>
                    <Col md={6} xs={6}>
                        <Link to="/account/create" className="page-title-btn">
                            <Button color="primary">Create</Button>
                        </Link>
                    </Col>
                </Row>
                <Row style={{ margin: '0px' }}>
                    <Table />
                </Row>
            </Container>
        </>
    );
};

export default FormValidation;
