import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { Card, CardBody, Col, Button, ButtonToolbar, Row } from 'reactstrap';
import FormField from '../../../../shared/components/form/FormField';
import renderSelectField from '../../../../shared/components/form/Select';

// api import
import { createTransaction, updateTransactionById, getAllAccount, getTransactionById } from '../../../../containers/Services/api';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateForm = () => {
    const { id } = useParams();
    const history = useHistory();
    // validation
    const validate = (values) => {
        const errors = {};
        if (!values.accountId) {
            errors.accountId = 'This field is required';
        }
        if (!values.date) {
            errors.date = 'This field is required';
        }
        if (!values.description) {
            errors.description = 'This field is required';
        }
        if (!values.currency) {
            errors.currency = 'This field is required';
        }
        if (!values.deposit) {
            errors.deposit = 'This field is required';
        }
        if (!values.withdrawal) {
            errors.withdrawal = 'This field is required';
        }
        if (!values.currentBalance) {
            errors.currentBalance = 'This field is required';
        }
        if (!values.hkdEquivalent) {
            errors.hkdEquivalent = 'This field is required';
        }
        return errors;
    };

    // get account by id
    const [transaction, setTransaction] = useState();
    const getTransaction = () => {
        getTransactionById(id)
            .then((res) => {
                setTransaction(res.data);
            })
            .catch((err) => {
                toast.error('Something went wrong!');
            });
    };

    const initialValueSchema = transaction
        ? {
              date: transaction.date,
              description: transaction.description,
              currency: transaction.currency,
              deposit: transaction.deposit,
              withdrawal: transaction.withdrawal,
              currentBalance: transaction.currentBalance,
              hkdEquivalent: transaction.hkdEquivalent,
              accountId: {
                  value: transaction.Account.id,
                  label: 'Account Number: ' + transaction.Account.accountNumber + ', Currency: ' + transaction.Account.accountCurrency,
              },
          }
        : '';

    const [accountData, setAccountData] = useState([]);
    const getAccountData = () => {
        getAllAccount()
            .then((res) => {
                let options = [];
                res.data.map((row) => {
                    return options.push({
                        value: row.id,
                        label: 'Account Number: ' + row.accountNumber + ', Currency: ' + row.accountCurrency,
                    });
                });
                setAccountData(options);
            })
            .catch((err) => {
                console.log(err);
                toast.error('Something went wrong!');
            });
    };

    // create inquiry
    const [buttonStatus, setButtonStatus] = useState(false);
    const onSubmit = (data) => {
        setButtonStatus(true);

        window.location.href.endsWith('create')
            ? createTransaction({ ...data, accountId: data.accountId.value })
                  .then((res) => {
                      toast.success('Added Successful');
                      history.push('/transaction/view');
                  })
                  .catch((err) => {
                      setButtonStatus(false);
                      toast.error('Something Went Wrong!');
                  })
            : updateTransactionById(id, { ...data, accountId: data.accountId.value })
                  .then((res) => {
                      toast.success('Update Successful');
                      history.push('/transaction/view');
                  })
                  .catch((err) => {
                      setButtonStatus(false);
                      toast.error('Something Went Wrong!');
                  });
    };

    useEffect(() => {
        getAccountData();
        if (!window.location.href.endsWith('create')) {
            getTransaction();
        }
    }, []);

    return (
        <>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={1000} />

            <Col md={12} lg={12} xl={12}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <div className="card__title col-md-12">
                                    <h5 className="bold-text col-md-12">
                                        {window.location.href.endsWith('create') ? 'New Transaction' : 'Update Transaction'}
                                    </h5>
                                </div>
                            </Col>
                            <Col>
                                <Link to="/account/view" className="page-title-btn">
                                    <Button color="primary">View</Button>
                                </Link>
                            </Col>
                        </Row>

                        <Form onSubmit={onSubmit} validate={validate} initialValues={initialValueSchema}>
                            {({ handleSubmit, form: { reset, pristine, submitting } }) => (
                                <form className="form col-md-8" autoComplete="off" onSubmit={handleSubmit}>
                                    <Col md={12}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Select Account</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="accountId"
                                                    component={renderSelectField}
                                                    type="text"
                                                    options={accountData}
                                                    isAboveError
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Date</span>
                                            <div className="form__form-group-field">
                                                <Field name="date" component={FormField} type="text" placeholder="Date" isAboveError />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Description</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="description"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="Description"
                                                    isAboveError
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Currency</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="currency"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="Currency"
                                                    isAboveError
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Deposit</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="deposit"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="Deposit"
                                                    isAboveError
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Withdrawal</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="withdrawal"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="Withdrawal"
                                                    isAboveError
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Current Balance</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="currentBalance"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="Current Balance"
                                                    isAboveError
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">HDK Equivalent</span>
                                            <div className="form__form-group-field ">
                                                <Field
                                                    name="hkdEquivalent"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="HDK Equivalent"
                                                    isAboveError
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <ButtonToolbar className="form__button-toolbar col-md-12">
                                        <Button color="primary" type="submit" disabled={buttonStatus} className="btn btn-primary">
                                            Submit
                                        </Button>
                                        <Button type="button" onClick={reset} disabled={pristine || submitting}>
                                            Clear
                                        </Button>
                                    </ButtonToolbar>
                                </form>
                            )}
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default CreateForm;
