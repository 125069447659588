import React from 'react';
import { Container, Row } from 'reactstrap';
import Form from './components/Form';

const FormValidation = () => {
    return (
        <Container>
            <Row>
                <Form />
            </Row>
        </Container>
    );
};

export default FormValidation;
