import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import FormField from '../../../shared/components/form/FormField';
// import validate from './validate';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import PasswordField from '../../../shared/components/form/Password';
//SERVICE
import { setData, setToken } from '../../../containers/Services/dataService';
import { userLogin } from '../../../containers/Services/api';
//TOSTIFY
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SuperLogInForm = () => {
    //VALIDATION
    const validate = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'Email field shouldn’t be empty';
        }

        if (!values.password) {
            errors.password = 'Password field shouldn’t be empty';
        }
        return errors;
    };
    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
    const [buttonStatus, setButtonStatus] = useState(false);

    const onSubmit = (data) => {
        setButtonStatus(true);

        const userData = {
            email: data.email,
            password: data.password,
            rememberMe: data.rememberMe,
        };

        userLogin(userData)
            .then(async (response) => {
                setToken(response.data.accessToken);
                setData(response.data.userData);

                toast.success(response?.data?.message || 'Successful Login');
                await sleep(1000);
                window.location.href = '/dashboard';
                setButtonStatus(false);
            })
            .catch((err) => {
                setButtonStatus(false);
                console.log(err);
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            });
    };

    return (
        <>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={1000} />

            <Form onSubmit={onSubmit} validate={validate}>
                {({ handleSubmit }) => (
                    <form className="form" onSubmit={handleSubmit}>
                        <div className="form__form-group">
                            <span className="form__form-group-label">Email</span>
                            <div className="form__form-group-field">
                                <div className="form__form-group-icon">
                                    <AccountOutlineIcon />
                                </div>
                                <Field
                                    name="email"
                                    component={FormField}
                                    type="text"
                                    placeholder="Email"
                                    isAboveError
                                />
                            </div>
                        </div>

                        <div className="form__form-group">
                            <span className="form__form-group-label">Password</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="password"
                                    component={PasswordField}
                                    placeholder="Password"
                                    className="input-without-border-radius"
                                    isAboveError
                                    keyIcon
                                />
                                {/* <div className="account__forgot-password">
                                <NavLink to="#">Forgot a password?</NavLink>
                            </div> */}
                            </div>
                        </div>

                        <div className="form__form-group">
                            <div className="form__form-group-field">
                                <Field
                                    name="rememberMe"
                                    component={renderCheckBoxField}
                                    label="Remember me"
                                    type="checkbox"
                                    style={{ color: 'white' }}
                                />
                            </div>
                        </div>

                        <div className="account__btns">
                            <Button
                                className="btn btn-primary account__btn account__btn--small"
                                type="submit"
                                style={{ color: 'white' }}
                                disabled={buttonStatus}
                            >
                                Sign In
                            </Button>
                        </div>
                    </form>
                )}
            </Form>
        </>
    );
};

export default SuperLogInForm;
