import React from 'react';
import { Link } from 'react-router-dom';
import LogInForm from './components/LogInForm';

const Login = () => (
    <>
        <div className="account account--background">
            <div className="account__wrapper">
                <Link className="topbar__logo" to="/" style={{ height: 75, width: '60%', margin: 'auto' }} />
                <div className="account__card">
                    <div className="account__head">
                        <h3 className="account__title">Welcome</h3>
                    </div>
                    <LogInForm />
                </div>
            </div>
        </div>
    </>
);

export default Login;
