import axios from './axios';
import service from './constant';
import axiosMian from 'axios';
import { getToken } from './dataService';

const baseUrl = service.API_URL;

// login api
export const userLogin = async (user) => {
    return await axiosMian.post(baseUrl + '/login', user, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// get logged user : always id = 01
export const getDashboardUser = async () => {
    return await axios.get('/dashboard-user');
};

// update dashboard user : always id = 1
export const updateDashboardUser = async (data) => {
    return await axios.put('/dashboard-user', data);
};

// get misc : always id = 1
export const getMisc = async () => {
    return await axios.get('/misc');
};

// update misc  : always id = 1
export const updateMisc = async (data) => {
    return await axios.put('/misc', data);
};

// get all account
export const getAllAccount = async () => {
    return await axios.get('/account');
};

// get account by id
export const getAccountById = async (id) => {
    return await axios.get('/account/' + id);
};

// create account
export const createAccount = async (data) => {
    return await axios.post('/account/', data);
};

// update account
export const updateAccountById = async (id, data) => {
    return await axios.put('/account/' + id, data);
};

// delete account
export const deleteAccountById = async (id) => {
    return await axios.delete('/account/' + id);
};

// get all Transaction
export const getAllTransaction = async () => {
    return await axios.get('/transaction');
};

// get Transaction by id
export const getTransactionById = async (id) => {
    return await axios.get('/transaction/' + id);
};

// create Transaction
export const createTransaction = async (data) => {
    return await axios.post('/transaction/', data);
};

// update Transaction
export const updateTransactionById = async (id, data) => {
    return await axios.put('/transaction/' + id, data, {
        'Content-Type': 'application/json',
        headers: {
            Authorization: getToken(),
        },
    });
};

// delete Transaction
export const deleteTransactionById = async (id) => {
    return await axios.delete('/transaction/' + id);
};
