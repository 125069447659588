import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CheckBox from 'devextreme-react/check-box';
import 'devextreme/data/odata/store';
import {
    Column,
    DataGrid,
    SearchPanel,
    Scrolling,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    Button,
    Selection,
    GroupPanel,
    Grouping,
    Export,
    FilterPanel,
    FilterBuilderPopup,
} from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
// api imports
import { deleteAccountById, getAllAccount } from '../../../../containers/Services/api';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import classNames from 'classnames';
import { ButtonToolbar, Modal } from 'reactstrap';
import { Button as Button2 } from 'reactstrap';

const pageSizes = [10, 25, 50, 100];

const Table = () => {
    const history = useHistory();
    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal((prevState) => !prevState);
    };

    const modalClass = classNames({
        'modal-dialog--colored': false,
        'modal-dialog--header': false,
    });

    // get all acount
    const [accountData, setAccountData] = useState([]);
    const getAccountData = () => {
        getAllAccount()
            .then((res) => {
                setAccountData(res.data);
            })
            .catch((err) => {
                console.log(err);
                toast.error('Something went wrong!');
            });
    };

    // delete account
    const [deleteId, setDeleteId] = useState('');
    const deleteAccount = () => {
        deleteAccountById(deleteId).then((data) => {
            if (data && data.status === 200) {
                getAccountData();
            } else {
                toast.error('Something Went Wrong!');
                history.push('/');
            }
            setDeleteId('');
        });
    };

    const onExporting = (e) => {
        const workbook = new Workbook();

        const worksheet = workbook.addWorksheet('Main Sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Data.xlsx');
            });
        });
        e.cancel = true;
    };

    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };

    const [showColumnLines, setShowColumnLines] = useState(false);
    const [showRowLines, setShowRowLines] = useState(true);
    const [showBorders, setShowBorders] = useState(true);
    const [rowAlternationEnabled, setRowAlternationEnabled] = useState(true);

    const onValueChanged = (e) => {
        switch (e.component.option('text')) {
            case 'Show Row Lines': {
                setShowRowLines(e.value);
                break;
            }

            case 'Show Borders': {
                setShowBorders(e.value);
                break;
            }

            case 'Alternating Row Color': {
                setRowAlternationEnabled(e.value);
                break;
            }

            default: {
                setShowColumnLines(e.value);
                break;
            }
        }
    };

    useEffect(() => {
        getAccountData();
    }, []);

    return (
        <>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={1000} />
            <div>
                <Modal
                    isOpen={modal}
                    toggle={toggle}
                    modalClassName={`ltr-support`}
                    className={`modal-dialog--danger ${modalClass}`}
                >
                    <div className="modal__header">
                        <button
                            className="lnr lnr-cross modal__close-btn"
                            aria-label="modal__close-btn"
                            type="button"
                            onClick={toggle}
                        />
                        <span className="lnr lnr-cross-circle modal__title-icon" />
                        <h4 className="text-modal  modal__title">Confirm Delete?</h4>
                    </div>
                    <div className="modal__body">Once Deleted, Cannot Be Undone.</div>
                    <ButtonToolbar className="modal__footer">
                        <Button2
                            className="modal_cancel"
                            onClick={(e) => {
                                setDeleteId('');
                                toggle();
                            }}
                        >
                            Cancel
                        </Button2>
                        <Button2
                            className="modal_ok"
                            outline={false}
                            color="danger"
                            onClick={(e) => {
                                toggle();
                                deleteAccount();
                            }}
                        >
                            Ok
                        </Button2>
                    </ButtonToolbar>
                </Modal>
            </div>
            <DataGrid
                dataSource={accountData}
                autoExpandAll={true}
                columnAutoWidth={true}
                keyExpr="id"
                onExporting={(e) => onExporting(e)}
                style={{
                    padding: '10px',
                    background: '#fff',
                    overflow: 'hidden',
                }}
                showColumnLines={showColumnLines}
                showRowLines={showRowLines}
                rowAlternationEnabled={rowAlternationEnabled}
                showBorders={showBorders}
            >
                <FilterRow visible={true} />

                <SearchPanel visible={true} highlightCaseSensitive={false} />
                <HeaderFilter visible={true} />
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={filterBuilderPopupPosition} />
                <Scrolling mode="standard" />
                <Paging defaultPageSize={10} defaultPageIndex={1} />
                <Selection mode="multiple" />
                <GroupPanel visible={true} />
                <Grouping autoExpandAll={true} />
                <Export enabled={true} allowExportSelectedData={true} />

                <Column type="buttons" caption="Action">
                    <Button
                        icon="edit"
                        onClick={(e) => {
                            history.push('/account/update/' + e.row.data.id);
                        }}
                    />

                    <Button
                        icon="trash"
                        onClick={(e) => {
                            setDeleteId(e.row.data.id);
                            toggle();
                        }}
                    />
                </Column>
                {/* <Column dataField="id" caption="Inquiry ID" /> */}
                <Column dataField="deposit" />
                <Column dataField="accountNumber" />
                <Column dataField="accountCurrency" />
                <Column dataField="availableBalance" />
                <Column dataField="currentBalance" />
                <Column dataField="dateFrom" />
                <Column dataField="dateTo" />
                <Column dataField="innerCurrent" />
                <Column dataField="innerAvailable" />
                <Column dataField="createdAt" caption="Date" dataType="date" format="dd/MM/yyyy" />

                <Pager
                    visible={true}
                    allowedPageSizes={pageSizes}
                    showPageSizeSelector={true}
                    displayMode="full"
                    showInfo={true}
                    showNavigationButtons={true}
                />
                <Paging defaultPageSize={10} />
            </DataGrid>

            <div
                className="options"
                style={{
                    width: '100%',
                    padding: '20px',
                    backgroundColor: '#fff',
                    marginTop: '5px',
                }}
            >
                <div className="caption" style={{ fontSize: '18px', fontWeight: '500' }}>
                    Options
                </div>
                <div
                    className="option"
                    style={{
                        width: '24%',
                        display: 'inline-block',
                        marginTop: '10px',
                    }}
                >
                    <CheckBox text="Show Column Lines" value={showColumnLines} onValueChanged={onValueChanged} />
                </div>
                &nbsp;
                <div
                    className="option"
                    style={{
                        width: '24%',
                        display: 'inline-block',
                        marginTop: '10px',
                    }}
                >
                    <CheckBox text="Show Row Lines" value={showRowLines} onValueChanged={onValueChanged} />
                </div>
                &nbsp;
                <div
                    className="option"
                    style={{
                        width: '24%',
                        display: 'inline-block',
                        marginTop: '10px',
                    }}
                >
                    <CheckBox text="Show Borders" value={showBorders} onValueChanged={onValueChanged} />
                </div>
                &nbsp;
                <div
                    className="option"
                    style={{
                        width: '24%',
                        display: 'inline-block',
                        marginTop: '10px',
                    }}
                >
                    <CheckBox
                        text="Alternating Row Color"
                        value={rowAlternationEnabled}
                        onValueChanged={onValueChanged}
                    />
                </div>
            </div>
        </>
    );
};

export default Table;
