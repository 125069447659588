export const setToken = (token) => {
    token && localStorage.setItem('token', token);
    return true;
};

export const setData = (data) => {
    data && localStorage.setItem('userData', JSON.stringify(data));
    return true;
};

export const getToken = () => {
    return localStorage.getItem('token');
};
export const getData = () => {
    return JSON.parse(localStorage.getItem('userData'));
};

export const logOut = (initialURL) => {
    localStorage.clear();
    window.location.href = initialURL ? initialURL : '/';
};
