import axios from 'axios';
import service from './constant';
import { getToken, logOut } from './dataService';

// const history = useHistory();

const instance = axios.create({
    baseURL: service.API_URL,
});

// ** Request Interceptor
instance.interceptors.request.use(
    (config) => {
        // ** Get token from localStorage
        const accessToken = getToken();

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
            // ** eslint-disable-next-line no-param-reassign
            config.headers.Authorization = accessToken;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// ** Add request/response interceptor
instance.interceptors.response.use(
    (response) => response,
    (error) => {
        // ** const { config, response: { status } } = error
        const { response } = error;

        // ** if (status === 401) {
        if (response && response.status === 401) {
            logOut();
            // window.location.href = '/login';
        }

        return Promise.reject(error);
    }
);

export default instance;
