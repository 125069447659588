import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';

import { RTLProps } from '../../shared/prop-types/ReducerProps';

import 'react-toastify/dist/ReactToastify.css';

const UserDashboard = ({ rtl }) => {
    return (
        <Container className="dashboard">
            <Row>
                {/* <Col>
                        <h3 className="page-title">{t('User dashboard')}</h3>
                    </Col> */}
                <Col md={6} xs={6}>
                    <h3 className="page-title">User dashboard</h3>
                </Col>
            </Row>
        </Container>
    );
};

UserDashboard.propTypes = {
    rtl: RTLProps.isRequired,
};

export default connect((state) => ({
    rtl: state.rtl,
}))(UserDashboard);
