import React, { useState, useEffect } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
//SERVICE CALL
import { logOut, getData } from '../../Services/dataService';
import avatar from '../../../shared/img/404/avatar.webp';
import { Link } from 'react-router-dom';

// const Ava = `https://www.freepik.com/free-vector/learning-concept-illustration_14230944.htm#query=education&position=7&from_view=search`;

const TopbarProfile = ({ onClick, data }) => {
    const [userData, setUserData] = useState();

    useEffect(() => {
        setUserData(getData());
    }, []);

    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className="topbar__profile">
            <button type="button" className="topbar__avatar" onClick={handleToggleCollapse}>
                <img className="topbar__avatar-img" src={avatar} alt="avatar" />

                <p className="topbar__avatar-name">{userData ? userData.userName : 'Unknown User'}</p>

                <DownIcon className="topbar__icon" />
            </button>
            {isCollapsed && <button type="button" aria-label="button collapse" className="topbar__back" onClick={handleToggleCollapse} />}
            <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
                <div className="topbar__menu">
                    {/* <Link className="topbar__link" to="/profile" onClick={onClick}>
                        <span className={`topbar__link-icon lnr lnr-user`} />
                        <p className="topbar__link-title">Profile</p>
                    </Link> */}

                    <Link className="topbar__link" to="#" onClick={() => logOut()}>
                        <span className={`topbar__link-icon lnr lnr-exit`} />
                        <p className="topbar__link-title">Log Out</p>
                    </Link>
                </div>
            </Collapse>
        </div>
    );
};

export default TopbarProfile;
