import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { Card, CardBody, Col, Button, ButtonToolbar, Row } from 'reactstrap';
import FormField from '../../../../shared/components/form/FormField';

// api import
import { getAccountById, updateAccountById, createAccount, getAllAccount } from '../../../../containers/Services/api';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateForm = () => {
    const { id } = useParams();
    const history = useHistory();
    // validation
    const validate = (values) => {
        const errors = {};
        if (!values.deposit) {
            errors.deposit = 'This field is required';
        }
        if (!values.accountNumber) {
            errors.accountNumber = 'This field is required';
        }
        if (!values.accountCurrency) {
            errors.accountCurrency = 'This field is required';
        }
        if (!values.availableBalance) {
            errors.availableBalance = 'This field is required';
        }
        if (!values.currentBalance) {
            errors.currentBalance = 'This field is required';
        }
        if (!values.dateFrom) {
            errors.dateFrom = 'This field is required';
        }
        if (!values.dateTo) {
            errors.dateTo = 'This field is required';
        }
        if (!values.innerCurrent) {
            errors.innerCurrent = 'This field is required';
        }
        if (!values.innerAvailable) {
            errors.innerAvailable = 'This field is required';
        }
        return errors;
    };

    // get account by id
    const [account, setAccount] = useState();
    const getAccountData = () => {
        getAccountById(id)
            .then((res) => {
                setAccount(res.data);
            })
            .catch((err) => {
                toast.error('Something went wrong!');
            });
    };

    const initialValueSchema = account
        ? {
              deposit: account.deposit,
              accountNumber: account.accountNumber,
              accountCurrency: account.accountCurrency,
              availableBalance: account.availableBalance,
              currentBalance: account.currentBalance,
              dateFrom: account.dateFrom,
              dateTo: account.dateTo,
              innerCurrent: account.innerCurrent,
              innerAvailable: account.innerAvailable,
          }
        : '';

    // create inquiry
    const [buttonStatus, setButtonStatus] = useState(false);
    const onSubmit = (data) => {
        setButtonStatus(true);

        window.location.href.endsWith('create')
            ? createAccount(data)
                  .then((res) => {
                      toast.success('Added Successful');
                      history.push('/account/view');
                  })
                  .catch((err) => {
                      setButtonStatus(false);
                      toast.error('Something Went Wrong!');
                  })
            : updateAccountById(id, data)
                  .then((res) => {
                      toast.success('Update Successful');
                      history.push('/account/view');
                  })
                  .catch((err) => {
                      setButtonStatus(false);
                      toast.error('Something Went Wrong!');
                  });
    };

    useEffect(() => {
        if (!window.location.href.endsWith('create')) {
            getAccountData();
        }
    }, []);

    return (
        <>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={1000} />

            <Col md={12} lg={12} xl={12}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <div className="card__title col-md-12">
                                    <h5 className="bold-text col-md-12">
                                        {window.location.href.endsWith('create') ? 'New Account' : 'Update Account'}
                                    </h5>
                                </div>
                            </Col>
                            <Col>
                                <Link to="/account/view" className="page-title-btn">
                                    <Button color="primary">View</Button>
                                </Link>
                            </Col>
                        </Row>

                        <Form onSubmit={onSubmit} validate={validate} initialValues={initialValueSchema}>
                            {({ handleSubmit, form: { reset, pristine, submitting } }) => (
                                <form className="form col-md-8" autoComplete="off" onSubmit={handleSubmit}>
                                    <Col md={12}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Deposit</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="deposit"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="Deposit"
                                                    isAboveError
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Account Number</span>
                                            <div className="form__form-group-field ">
                                                <Field
                                                    name="accountNumber"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="Account Number"
                                                    isAboveError
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Account Currency</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="accountCurrency"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="Account Currency"
                                                    isAboveError
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Available Balance</span>
                                            <div className="form__form-group-field ">
                                                <Field
                                                    name="availableBalance"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="Available Balance"
                                                    isAboveError
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Current Balance</span>
                                            <div className="form__form-group-field ">
                                                <Field
                                                    name="currentBalance"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="Current Balance"
                                                    isAboveError
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Date From</span>
                                            <div className="form__form-group-field ">
                                                <Field
                                                    name="dateFrom"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="Date From"
                                                    isAboveError
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Date To</span>
                                            <div className="form__form-group-field ">
                                                <Field
                                                    name="dateTo"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="Date To"
                                                    isAboveError
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Inner Current</span>
                                            <div className="form__form-group-field ">
                                                <Field
                                                    name="innerCurrent"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="Inner Current"
                                                    isAboveError
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Inner Available</span>
                                            <div className="form__form-group-field ">
                                                <Field
                                                    name="innerAvailable"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="Inner Available"
                                                    isAboveError
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <ButtonToolbar className="form__button-toolbar col-md-12">
                                        <Button
                                            color="primary"
                                            type="submit"
                                            disabled={buttonStatus}
                                            className="btn btn-primary"
                                        >
                                            Submit
                                        </Button>
                                        <Button type="button" onClick={reset} disabled={pristine || submitting}>
                                            Clear
                                        </Button>
                                    </ButtonToolbar>
                                </form>
                            )}
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default CreateForm;
