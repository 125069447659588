import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { Card, CardBody, Col, Button, ButtonToolbar, Row } from 'reactstrap';
import FormField from '../../../shared/components/form/FormField';

// api import
import { getDashboardUser, getMisc, updateDashboardUser, updateMisc } from '../../../containers/Services/api';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateForm = () => {
    // validation
    const validate = (values) => {
        const errors = {};
        if (!values.loggedUserName) {
            errors.loggedUserName = 'This field is required';
        }
        if (!values.email) {
            errors.email = 'This field is required';
        }

        if (!values.mobile) {
            errors.mobile = 'This field is required';
        }
        if (!values.lastLogin) {
            errors.lastLogin = 'This field is required';
        }

        return errors;
    };

    // get account by id
    const [data, setData] = useState();
    const getMiscData = () => {
        getDashboardUser()
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                toast.error('Something went wrong!');
            });
    };

    const initialValueSchema = data
        ? {
              loggedUserName: data.loggedUserName,
              email: data.email,
              mobile: data.mobile,
              lastLogin: data.lastLogin,
          }
        : '';

    // create inquiry
    const [buttonStatus, setButtonStatus] = useState(false);
    const onSubmit = (data) => {
        setButtonStatus(true);
        updateDashboardUser(data)
            .then((res) => {
                toast.success('Update Successful');
                setButtonStatus(false);

                // history.push('/account/view');
            })
            .catch((err) => {
                setButtonStatus(false);
                toast.error('Something Went Wrong!');
            });
    };

    useEffect(() => {
        getMiscData();
    }, []);

    return (
        <>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={1000} />

            <Col md={12} lg={12} xl={12}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <div className="card__title col-md-12">
                                    <h5 className="bold-text col-md-12">Update Misc</h5>
                                </div>
                            </Col>
                        </Row>

                        <Form onSubmit={onSubmit} validate={validate} initialValues={initialValueSchema}>
                            {({ handleSubmit, form: { reset, pristine, submitting } }) => (
                                <form className="form col-md-8" autoComplete="off" onSubmit={handleSubmit}>
                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Logged UserName</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="loggedUserName"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="Logged UserName"
                                                    isAboveError
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Email</span>
                                            <div className="form__form-group-field ">
                                                <Field name="email" component={FormField} type="text" placeholder="Email" isAboveError />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Mobile</span>
                                            <div className="form__form-group-field">
                                                <Field name="mobile" component={FormField} type="text" placeholder="Mobile" isAboveError />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Last Login</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="lastLogin"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="Last Login"
                                                    isAboveError
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <ButtonToolbar className="form__button-toolbar col-md-12">
                                        <Button color="primary" type="submit" disabled={buttonStatus} className="btn btn-primary">
                                            Submit
                                        </Button>
                                        <Button type="button" onClick={reset} disabled={pristine || submitting}>
                                            Clear
                                        </Button>
                                    </ButtonToolbar>
                                </form>
                            )}
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default CreateForm;
